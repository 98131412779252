/** @format */

"use client";
import Link from "next/link";
import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import DemoButton from "../Demo/DemoButton";

const BurgerMenu = ({ pathname = "/" }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const [isSubmenuVisible, setSubmenuVisible] = useState(false);
  const toggleSubmenu = () => {
    setSubmenuVisible(!isSubmenuVisible);
  };

  const ResourcesDropdown = () => (
    <ul
      className={`nav_subul mobile_subnav ${
        isSubmenuVisible ? "showSubmenu" : "hidesubmenu"
      }`}
    >
      <li className="header-text">
        <p className="navbar_mobile">
          <span>Industries</span>
        </p>
      </li>
      <li>
        <Link
          href={"/digital-signage-software-for-hospitals"}
          key={"healthcare"}
          aria-label={`healthcare`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/digital-signage-software-for-hospitals" !== "/"
                  ? pathname.includes("/digital-signage-software-for-hospitals")
                    ? "currentPage"
                    : ""
                  : pathname === "/digital-signage-software-for-hospitals "
                  ? "currentPage"
                  : ""
              }
            >
              Healthcare
            </span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={"/digital-signage-software-for-education"}
          key={"education"}
          aria-label={`education`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/digital-signage-software-for-education" !== "/"
                  ? pathname.includes("/digital-signage-software-for-education")
                    ? "currentPage"
                    : ""
                  : pathname === "/digital-signage-software-for-education"
                  ? "currentPage"
                  : ""
              }
            >
              Education
            </span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={"/digital-signage-software-for-qsr"}
          key={"qsrchains"}
          aria-label={`qsrchains`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/digital-signage-software-for-qsr" !== "/"
                  ? pathname.includes("/digital-signage-software-for-qsr")
                    ? "currentPage"
                    : ""
                  : pathname === "/digital-signage-software-for-qsr"
                  ? "currentPage"
                  : ""
              }
            >
              QSR chains
            </span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={"/digital-signage-software-for-fitness"}
          key={"fitness"}
          aria-label={`fitness`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/digital-signage-software-for-fitness" !== "/"
                  ? pathname.includes("/digital-signage-software-for-fitness")
                    ? "currentPage"
                    : ""
                  : pathname === "/digital-signage-software-for-fitness"
                  ? "currentPage"
                  : ""
              }
            >
              Fitness
            </span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={"/digital-signage-software-for-manufacturing-industry"}
          key={"manufacturing"}
          aria-label={`manufacturing`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/digital-signage-software-for-manufacturing-industry" !== "/"
                  ? pathname.includes(
                      "/digital-signage-software-for-manufacturing-industry"
                    )
                    ? "currentPage"
                    : ""
                  : pathname === "/digital-signage-software-for-manufacturing-industry"
                  ? "currentPage"
                  : ""
              }
            >
              Manufacturing
            </span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={"/digital-signage-software-for-retail"}
          key={"retail"}
          aria-label={`retail`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/digital-signage-software-for-retail" !== "/"
                  ? pathname.includes("/digital-signage-software-for-retail")
                    ? "currentPage"
                    : ""
                  : pathname === "/digital-signage-software-for-retail"
                  ? "currentPage"
                  : ""
              }
            >
              Retail
            </span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={"/digital-signage-software-for-corporate"}
          key={"corporate"}
          aria-label={`corporate`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/digital-signage-software-for-corporate" !== "/"
                  ? pathname.includes("/digital-signage-software-for-corporate")
                    ? "currentPage"
                    : ""
                  : pathname === "/digital-signage-software-for-corporate"
                  ? "currentPage"
                  : ""
              }
            >
              Corporate
            </span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={"/digital-signage-software-for-transportation-supply-chain"}
          key={"transportation"}
          aria-label={`transportation`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/digital-signage-software-for-transportation-supply-chain" !==
                "/"
                  ? pathname.includes(
                      "/digital-signage-software-for-transportation-supply-chain"
                    )
                    ? "currentPage"
                    : ""
                  : pathname ===
                    "/digital-signage-software-for-transportation-supply-chain"
                  ? "currentPage"
                  : ""
              }
            >
              Transportation
            </span>
          </p>
        </Link>
      </li>

      <li className="header-text">
        <p className="navbar_mobile">
          <span>Use cases</span>
        </p>
      </li>
      <li>
        <Link
          href={"#"}
          key={" Employee engagement"}
          aria-label={` Employee engagement`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span>Employee engagement</span>
          </p>
        </Link>
      </li>

      <li>
        <Link
          href={"#"}
          key={" Employee engagement"}
          aria-label={` Employee engagement`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span>Customer engagement</span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={"#"}
          key={" Employee engagement"}
          aria-label={` Employee engagement`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span>Screen network management</span>
          </p>
        </Link>
      </li>

      <li>
        <Link
          href={"#"}
          key={" Employee engagement"}
          aria-label={` Employee engagement`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span>Deskless workers engagement</span>
          </p>
        </Link>
      </li>

      <li>
        <Link
          href={"/digital-signage-cms-software"}
          key={"digital-signage-cms-software"}
          aria-label={` digital-signage-cms-software`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/digital-signage-cms-software" !== "/"
                  ? pathname.includes("/digital-signage-cms-software")
                    ? "currentPage"
                    : ""
                  : pathname === "/digital-signage-cms-software"
                  ? "currentPage"
                  : ""
              }
            >
              Digital signage CMS
            </span>
          </p>
        </Link>
      </li>
      <li>
        <Link
          href={"/blog"}
          key={" blog"}
          aria-label={` blog`}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/blog" !== "/"
                  ? pathname.includes("/blog")
                    ? "currentPage"
                    : ""
                  : pathname === "/blog"
                  ? "currentPage"
                  : ""
              }
            >
              blog
            </span>
          </p>
        </Link>
      </li>
    </ul>
  );

  return (
    <Menu
      left
      isOpen={isMenuOpen}
      onStateChange={handleStateChange}
      customCrossIcon={<CloseIcon sx={{ color: "#226DB4" }} />}
      customBurgerIcon={<MenuIcon sx={{ color: "#226DB4" }} />}
    >
      <div className="nav_container_right">
        <Link
          href={"/"}
          key={"home"}
          onClick={() => {
            setMenuOpen(false);
          }}
          aria-label={`home`}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/" !== "/"
                  ? pathname.includes("/")
                    ? "currentPage"
                    : ""
                  : pathname === "/"
                  ? "currentPage"
                  : ""
              }
            >
              Home
            </span>
          </p>
        </Link>
        <div
          className={`navbar_mobile withsubmenunav ${
            isSubmenuVisible ? "showSubmenu" : "dddd"
          }`}
        >
          <span
            onClick={toggleSubmenu}
            className={`mainspan ${
              pathname.includes("/digital-signage-software-for-hospitals") ||
              pathname.includes("/digital-signage-software-for-education") ||
              pathname.includes("/digital-signage-software-for-qsr") ||
              pathname.includes("/digital-signage-software-for-fitness") ||
              pathname.includes(
                "/digital-signage-software-for-manufacturing-industry"
              ) ||
              pathname.includes("/retail") ||
              pathname.includes("/digital-signage-software-for-corporate") ||
              pathname.includes(
                "/digital-signage-software-for-transportation-supply-chain"
              )
                ? "currentPage"
                : ""
            }`}
          >
            Industries
          </span>
          <ResourcesDropdown />
        </div>
        <Link
          href={"/features"}
          key={"features"}
          onClick={() => {
            setMenuOpen(false);
          }}
          aria-label={`features`}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/features" !== "/"
                  ? pathname.includes("/features")
                    ? "currentPage"
                    : ""
                  : pathname === "/features"
                  ? "currentPage"
                  : ""
              }
            >
              Features
            </span>
          </p>
        </Link>
        <Link
          href={"/blog"}
          key={"blog"}
          onClick={() => {
            setMenuOpen(false);
          }}
          aria-label={`blog`}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/blog" !== "/"
                  ? pathname.includes("/blog")
                    ? "currentPage"
                    : ""
                  : pathname === "/blog"
                  ? "currentPage"
                  : ""
              }
            >
              Blog
            </span>
          </p>
        </Link>

        <Link
          href={"/contact-us"}
          key={"contact-us"}
          onClick={() => {
            setMenuOpen(false);
          }}
          aria-label={`contact-us`}
        >
          <p className="navbar_mobile">
            <span
              className={
                "/contact-us" !== "/"
                  ? pathname.includes("/contact-us")
                    ? "currentPage"
                    : ""
                  : pathname === "/contact-us"
                  ? "currentPage"
                  : ""
              }
            >
              Contact Us
            </span>
          </p>
        </Link>

        <DemoButton navBar aria-hidden="true" />
      </div>
    </Menu>
  );
};

export default BurgerMenu;
