// --------------------- Home page
// Media_player
import android_tv_report_features from "@/Helper/images/androidDigital/android_tv_report_features.svg";
import content_playlist_scheduling from "@/Helper/images/androidDigital/content_playlist_scheduling.svg";
import Easy_accessibility from "@/Helper/images/androidDigital/Easy_accessibility.svg";
import easy_device_pairing from "@/Helper/images/androidDigital/easy_device_pairing.svg";
import Functions_in_offline_online from "@/Helper/images/androidDigital/Functions_in_offline_&_online.svg";
import Real_time_content_syncing from "@/Helper/images/androidDigital/Real_time_content_syncing.svg";
// software_category
import Ads_report from "@/Helper/images/ourFeatureSection/Ads_report.svg";
import Andrioid_tv_report from "@/Helper/images/ourFeatureSection/Andrioid_tv_report.svg";
import campaign_management from "@/Helper/images/ourFeatureSection/campaign_management.svg";
import Content_management_system from "@/Helper/images/ourFeatureSection/Content_management_system.svg";
import Media from "@/Helper/images/ourFeatureSection/Media.svg";
import on_premise_deployment from "@/Helper/images/ourFeatureSection/on_premise_deployment.svg";
import Playlist from "@/Helper/images/ourFeatureSection/Playlist.svg";
import screen_uptime_report from "@/Helper/images/ourFeatureSection/screen_uptime_report.svg";
import Unlinted_screens from "@/Helper/images/ourFeatureSection/Unlinted_screens.svg";

// feature list

import feature_1 from "@/Helper/images/featurelist/Instinctive scheduling.png";
import feature_2 from "@/Helper/images/featurelist/Display media on-screen.png";
import feature_3 from "@/Helper/images/featurelist/Create intuitive playlist.png";
import feature_4 from "@/Helper/images/featurelist/Remote screen management.png";
import feature_5 from "@/Helper/images/featurelist/on-premise 1 (5).png";
import feature_6 from "@/Helper/images/featurelist/on-premise 1 (3).png";
import feature_7 from "@/Helper/images/featurelist/on-premise 1 (4).png";
import feature_8 from "@/Helper/images/featurelist/Real-time display.png";
import feature_9 from "@/Helper/images/featurelist/On-premise deployment.png";

export const Media_player = [
  {
    id: 1,
    img: Easy_accessibility,
    title: `Easy accessibility`,
    content: `The digital signage system is easily accessible and compatible. Businesses can employ digital signage systems for complete management over digital screen networks. It creates more impact on audiences and helps your business grow.`,
  },
  {
    id: 2,
    img: Functions_in_offline_online,
    title: `Functions in offline & online`,
    content: `The extensive functionalities of Signagemint digital signage system involved online and offline playing of contents across various places. Therefore, it makes easy offline & online playing of content on loop.`,
  },
  {
    id: 3,
    img: content_playlist_scheduling,
    title: `Content playlist scheduling`,
    content: `Templates & slides can be configured for playing a certain length of time. The screens are updated seamlessly with the digital signage system. It automates and schedules a content playlist to play on time.`,
  },
  {
    id: 4,
    img: android_tv_report_features,
    title: `Android TV report features`,
    content: `The digital Android TV report creates a complete report and manages screen networks effectively. It comprises features such as unlimited Android OS access, user roles, and improved control over screen management.`,
  },
  {
    id: 5,
    img: easy_device_pairing,
    title: `Easy device pairing`,
    content: `One can experience a smooth pairing process across different devices. Digital signage can be paired with devices like Android TV, Windows using OTP making it user-friendly and easily accessible.`,
  },
  {
    id: 6,
    img: Real_time_content_syncing,
    title: `Real-time content syncing`,
    content: `Signagemint offers real-time content syncing so that digital content remains syncing with time making it highly engaging for potential audiences. Content can be updated and modified on digital displays easily with the help of digital signage.`,
  },
];

export const software_category = [
  {
    id: 1,
    img: Unlinted_screens,
    title: "Unlimited screens",
    content: `Now it has become easier to manage an unlimited number of screens from a single source with our powerful digital signage system`,
  },
  {
    id: 2,
    img: Media,
    title: "Media",
    content: `Managing media has never been easier before. Signagemint digital signage helps in creating effective media content & updating from time to time.`,
  },
  {
    id: 3,
    img: Playlist,
    title: "Playlist",
    content: `Digital signage creates the perfect playlist rendering content scheduling, playing content on loop, managing time slides and many more.`,
  },
  {
    id: 4,
    img: Content_management_system,
    title: "Content management system",
    content: `Digital signage CMS is a great software platform that enables users to publish digital content including videos, images and so forth.`,
  },
  {
    id: 5,
    img: campaign_management,
    title: "Campaign management",
    content: `Signagemint helps businesses & brands improve marketing operations by means of broadcasting campaigns and improved content.`,
  },
  {
    id: 6,
    img: Ads_report,
    title: "Ads report",
    content: `Get an effective advertisement report summarizing the advertising cycles playing on a loop through digital screens through potential digital signage systems.`,
  },
  {
    id: 7,
    img: screen_uptime_report,
    title: "Screen up-time report",
    content: `Implementing digital signage analytics will assist in monitoring the all-inclusive screen up-time and performance of digital screens. It renders a timely report.`,
  },
  {
    id: 8,
    img: Andrioid_tv_report,
    title: "Android TV report",
    content: `Having Signagemint for Android TV, users can easily build in Android OS for running the varied digital signage content presentations.`,
  },
  {
    id: 9,
    img: on_premise_deployment,
    title: "On-premise deployment",
    content: `Signagemint's on-premise solution enables businesses to self-host digital signage CMS on the local server of enterprises and businesses.`,
  },
];

export const nav_links = [
  { id: 1, href: "/", pathName: "Home" },
  { id: 2, href: "/features", pathName: "Features" },
  { id: 4, href: "/blog", pathName: "Blogs" },
  { id: 5, href: "/contact-us", pathName: "Contact Us" },
  {
    id: 7,
    href: "https://signagemint.zohodesk.in/portal/en/home",
    pathName: "Support center",
    target: "_blank",
  },
];

// Feature list page - content

export const feature_list = [
  {
    // title:'Scheduling',
    subTitle: "Instinctive scheduling",
    content:
      "Display distinct content at different times of the day to target specific audiences and clients. It renders intuitive scheduling and helps in planning relentlessly.",
    img: feature_1,
  },
  {
    // title:'Media',
    subTitle: "Display media on-screen",
    content:
      "Utilize the power of images and videos to make your messages stand out from the crowd. Display videos on loop, live stream and many more with digital signage systems.",
    img: feature_2,
  },
  {
    // title:'Playlist',
    subTitle: "Create intuitive playlist",
    content:
      "One can create a brilliant playlist harnessing favorite media like webpages, media, images, & documents with an easy drag-drop tool. It's time to display instant transitions without a break or black screen.",
    img: feature_3,
  },
  {
    // title:'Remote',
    subTitle: "Remote screen management",
    content:
      "From content to players through online portals, one can manage everything with a digital signage system. It has now become easier to upload, update and display new content remotely.",
    img: feature_4,
  },
  {
    // title:'Upgrades',
    subTitle: "Auto-upgrades",
    content:
      "Having automatic software upgrades, the system gets updated with relevant features that make it hassle-free. The auto-upgrades make the system more flexible and fast.",
    img: feature_5,
  },
  {
    // title:'Management',
    subTitle: "Seamless management",
    content:
      "With the implementation of a digital signage system, one can set distinct content schedules and monitor effectively. It also helps in managing several screens remotely.",
    img: feature_6,
  },
  {
    // title:'Security',
    subTitle: "Ultimate security & reliability",
    content:
      "Signagemint digital signage software is integrated with advanced security features to prevent any kind of tampering that could potentially cause disruption in the digital screens.",
    img: feature_7,
  },
  {
    // title:'real-time',
    subTitle: "Real-time display",
    content:
      "The digital signage system offers a real-time display of content and remains updated with the latest display of materials. The real-time display enables businesses to showcase their content to audiences.",
    img: feature_8,
  },
  {
    // title:'Deploymon-premiseent',
    subTitle: "Easy content update",
    content:
      "With the help of cloud centralized solutions, content can be easily updated to digital screens remotely. A cloud system enables easy content updates making it easier for admins to control management.",
    img: feature_3,
  },
  {
    // title:'on-premise',
    subTitle: "On-premise deployment",
    content:
      "The on-premise deployment offers advanced security, scalability, faster media upload and control over unlimited screens. One can accomplish seamless integration with different industry verticals.",
    img: feature_9,
  },
];
