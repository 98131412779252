/** @format */

"use client";
import React, { useEffect, useState } from "react";
import "./Footer.css";
import { apiService } from "@/services/api.services";
import Link from "next/link";
import { nav_links } from "@/Helper/content";
import Footer_form from "./Footer_form";

const Footer = () => {
  const [footerData, setFooterData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchFooterData() {
      try {
        const resp = await apiService.getAllUtilityData();
        if (resp.status === 200) {
          setFooterData(resp.data?.data?.attributes);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        setError(err);
      }
    }

    fetchFooterData();
  }, []);
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <footer className="footer_area ">
      <div className="container">
        <div className=" footer_container ">
          <div className=" footer_content_container">
            <div className="">
              <p className="footer_heading">Company</p>
              <div className="footer_nav_area">
                {nav_links.map((nav) => (
                  <p className="footer_text footer_link" key={nav.id}>
                    <Link
                      href={nav.href}
                      target={nav.id === 7 ? "_blank" : undefined}
                      rel={nav.id === 7 ? "noopener noreferrer" : undefined}
                    >
                      {nav.pathName}
                    </Link>
                  </p>
                ))}
              </div>
            </div>
            <div className="">
              <p className="footer_heading">Industries</p>
              <div className="footer_nav_area">
                <p className="footer_text " key={"digital"}>
                  <Link href={"/digital-signage-software-for-hospitals"}>
                    Healthcare
                  </Link>
                </p>
                <p className="footer_text " key={"digital"}>
                  <Link href={"/digital-signage-software-for-education"}>
                    Education
                  </Link>
                </p>
                <p className="footer_text " key={"digital"}>
                  <Link href={"/digital-signage-software-for-qsr"}>
                    QSR chains
                  </Link>
                </p>
                <p className="footer_text " key={"digital"}>
                  <Link href={"/digital-signage-software-for-fitness"}>
                    Fitness
                  </Link>
                </p>
                <p className="footer_text " key={"digital"}>
                  <Link
                    href={
                      "/digital-signage-software-for-manufacturing-industry"
                    }
                  >
                    Manufacturing
                  </Link>
                </p>
                <p className="footer_text " key={"digital"}>
                  <Link href={"/digital-signage-software-for-retail"}>
                    Retail
                  </Link>
                </p>
                <p className="footer_text " key={"digital"}>
                  <Link href={"/digital-signage-software-for-corporate"}>
                    Corporate
                  </Link>
                </p>
                <p className="footer_text " key={"digital"}>
                  <Link
                    href={
                      "/digital-signage-software-for-transportation-supply-chain"
                    }
                  >
                    Transportation
                  </Link>
                </p>
              </div>
            </div>
            <div className="">
              <p className="footer_heading">Quick Links</p>
              <div className="footer_nav_area">
                <p className="footer_text " key={"Digital"}>
                  <Link href={"/digital-signage-cms-software"}>
                    Digital signage
                  </Link>{" "}
                  CMS
                </p>

                <p className="footer_text " key={"Privacy"}>
                  <Link href={"/deskless-employees-engagement-system"}>
                  Transform your Workplace Internal Communications
                  </Link>
                </p>

                <p className="footer_text " key={"Privacy"}>
                  <Link href={"/privacy-policy"}>Privacy policy</Link>
                </p>
                <p className="footer_text " key={"Terms and condition"}>
                  <Link href={"/terms-and-conditions"}>
                    Terms and condition
                  </Link>
                </p>
              </div>
            </div>
            <div className="footer_inner_container">
              <p className="footer_heading">Contact Us</p>
              <Footer_form />
            </div>
          </div>
          <p>{footerData?.rights_reserved}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
