/** @format */

import { useFormik } from "formik";
import * as Yup from "yup";
import { useRouter } from "next/navigation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomButton from "@/component/Buttons/CustomButton";
import { apiService } from "@/services/api.services";
import "./EmailFooter.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import React, { useState } from "react";
// import {} from "react-router-dom";

const FooterForm = () => {
  const router = useRouter();
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [isSuccess, setIsSuccess] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .test("phone-valid", "Phone number is not valid", (value) =>
        isValidPhoneNumber(value, selectedCountry)
      ),
    message: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log("Submitted values Praveen:", values);
      const payload = {
        data: {
          first_name: values.firstName,
          email: values.email,
          phone_number: values.phoneNumber,
          message: values.message,
        },
      };

      try {
        const contactUsResponse = await apiService.postContactUsDetails(
          payload
        );
        if (contactUsResponse.status === 200) {
          const accessTokenResponse = await apiService.getRefreshAccessToken();
          const newAccessToken = accessTokenResponse.access_token;

          const leadData = {
            last_name: values.firstName,
            email: values.email,
            phone_number: values.phoneNumber,
            description: values.message,
          };

          const leadResponse = await apiService.createLead(
            leadData,
            newAccessToken
          );

          console.log(
            "---------leadResponse----------->",
            leadResponse.data[0].code
          );

          if (leadResponse && leadResponse.data[0].code === "SUCCESS") {
            console.log("--------1------->");
            apiService.sendThankyouMail({ data: payload.data });
            setIsSuccess(true);
            resetForm();
            router.push("/thankyou");
          } else {
            console.log("--------2------->");

            throw new Error("Failed to create lead");
          }
        }
      } catch (error) {
        console.error("Error in onSubmit:", error);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="contactform_footer">
        <TextInput
          className="footer_values"
          placeholder="First Name"
          id="firstName"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.touched.firstName && formik.errors.firstName}
        />
      </div>

      <div className="contact_footer">
        <TextInput
          className="footer_values"
          placeholder="Email"
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.touched.email && formik.errors.email}
        />

        <div className="custom_number_container_footer">
          <PhoneInput
            country={selectedCountry.toLowerCase()}
            name="phoneNumber"
            id="phoneNumber"
            className="mobilephone"
            inputProps={{
              name: "phoneNumber",
              id: "phoneNumber",
            }}
            copyNumbersOnly
            enableSearch
            isValid
            value={formik.values.phoneNumber}
            onChange={(value, country) => {
              formik.setFieldValue("phoneNumber", value);
              setSelectedCountry(country.countryCode.toUpperCase());
            }}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <p className="error-message">{formik.errors.phoneNumber ?? ""}</p>
          )}
        </div>
      </div>
      <div className="contact_footer_message">
        <TextInput
          className="message_footer"
          placeholder="Message"
          id="message"
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errorText={formik.touched.message && formik.errors.message}
          multi={true}
        />
      </div>

      <div>
        <CustomButton
          className="footer_button"
          isLoading={formik.isSubmitting}
          Text={isSuccess ? "Submitted" : "Submit"}
          PrimaryColorBtn
          type={"submit"}
        />
      </div>
    </form>
  );
};

const TextInput = ({
  errorText,
  label,
  placeholder,
  value,
  onChange,
  multi,
  id,
  ...props
}) => {
  if (multi) {
    return (
      <div className="input_footer">
        <label htmlFor={id}>{label}</label>
        <textarea
          {...props}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          cols="30"
          rows="5"
        />
        {errorText && <p className="error-message">{errorText}</p>}
      </div>
    );
  }
  return (
    <div className="input_footer">
      <label htmlFor={id}>{label}</label>
      <input
        {...props}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {errorText && <p className="error-message">{errorText}</p>}
    </div>
  );
};

export default FooterForm;
