/** @format */

"use client";
import "./NavBar.css";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { Snackbar } from "@mui/material";
import { apiService } from "@/services/api.services";
import CustomButton from "../Buttons/CustomButton";
import BurgerMenu from "./BurgerMenu";

const NavBar = () => {
  const pathname = usePathname();
  const [isOnScroll, setIsOnScroll] = useState(false);
  const [logo, setLogo] = useState(null);
  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    apiService.getAllUtilityData().then((resp) => {
      if (resp.status === 200) {
        setLogo(`${resp.data?.data?.attributes?.logo?.data?.attributes?.url}`);
      }
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setIsOnScroll(true);
      } else {
        setIsOnScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ResourcesDropdown = () => (
    <ul className="nav_subulweb nav_subul ResourcesDropdown">
      <div>
        <li>
          <Link
            href={"/digital-signage-software-for-hospitals"}
            key={"healthcare"}
            aria-label={`healthcare`}
          >
            <p className="navbar_desktop">
              <span
                className={
                  pathname.includes("/digital-signage-software-for-hospitals")
                    ? "currentPage"
                    : ""
                }
              >
                Healthcare
              </span>
            </p>
          </Link>
        </li>
        <li>
          <Link
            href={"/digital-signage-software-for-education"}
            key={"education"}
            aria-label={`education`}
          >
            <p className="navbar_desktop">
              <span
                className={
                  pathname.includes("/digital-signage-software-for-education")
                    ? "currentPage"
                    : ""
                }
              >
                Education
              </span>
            </p>
          </Link>
        </li>
        <li>
          <Link
            href={"/digital-signage-software-for-qsr"}
            key={"qsrchains"}
            aria-label={`qsrchains`}
          >
            <p className="navbar_desktop">
              <span
                className={
                  pathname.includes("/digital-signage-software-for-qsr")
                    ? "currentPage"
                    : ""
                }
              >
                QSR chains
              </span>
            </p>
          </Link>
        </li>
        <li>
          <Link
            href={"/digital-signage-software-for-fitness"}
            key={"fitness"}
            aria-label={`fitness`}
          >
            <p className="navbar_desktop">
              <span
                className={
                  pathname.includes("/digital-signage-software-for-fitness")
                    ? "currentPage"
                    : ""
                }
              >
                Fitness
              </span>
            </p>
          </Link>
        </li>
        <li>
          <Link
            href={"/digital-signage-software-for-manufacturing-industry"}
            key={"manufacturing"}
            aria-label={`manufacturing`}
          >
            <p className="navbar_desktop">
              <span
                className={
                  pathname.includes(
                    "/digital-signage-software-for-manufacturing-industry"
                  )
                    ? "currentPage"
                    : ""
                }
              >
                Manufacturing
              </span>
            </p>
          </Link>
        </li>
        <li>
          <Link
            href={"/digital-signage-software-for-retail"}
            key={"retail"}
            aria-label={`retail`}
          >
            <p className="navbar_desktop">
              <span
                className={
                  pathname.includes("/digital-signage-software-for-retail")
                    ? "currentPage"
                    : ""
                }
              >
                Retail
              </span>
            </p>
          </Link>
        </li>
        <li>
          <Link
            href={"/digital-signage-software-for-corporate"}
            key={"corporate"}
            aria-label={`corporate`}
          >
            <p className="navbar_desktop">
              <span
                className={
                  pathname.includes("/digital-signage-software-for-corporate")
                    ? "currentPage"
                    : ""
                }
              >
                Corporate
              </span>
            </p>
          </Link>
        </li>
        <li>
          <Link
            href={"/digital-signage-software-for-transportation-supply-chain"}
            key={"transportation"}
            aria-label={`transportation`}
          >
            <p className="navbar_desktop">
              <span
                className={
                  pathname.includes(
                    "/digital-signage-software-for-transportation-supply-chain"
                  )
                    ? "currentPage"
                    : ""
                }
              >
                Transportation
              </span>
            </p>
          </Link>
        </li>
      </div>
    </ul>
  );

  return (
    <div
      className={`nav_area ${isOnScroll ? "onscroll_bg" : ""} ${
        pathname === "/" || pathname === "/features" || pathname === "/pricing"
          ? "nav_area_home"
          : "nav_area_blog"
      }`}
    >
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={isOpen}
        autoHideDuration={3000}
        onClose={() => setisOpen(false)}
        message="Coming Soon!"
      />
      <div className="container">
        <nav className="nav_container">
          <div className="nav_container_left">
            {logo && (
              <Link href={"/"} key={"logo"}>
                <Image
                  src={logo}
                  alt="logo"
                  width={245}
                  height={42}
                  className="nav_logo"
                />
              </Link>
            )}
          </div>
          <div className="nav_container_right">
            <div className="nav_container_right_inner">
              <Link href={"/"} key={"home"} aria-label={`home`}>
                <p className="navbar_desktop">
                  <span className={pathname === "/" ? "currentPage" : ""}>
                    Home
                  </span>
                </p>
              </Link>
              <Link href={"/features"} key={"features"} aria-label={`features`}>
                <p className="navbar_desktop">
                  <span className={pathname === "/features" ? "currentPage" : ""}>
                    Features
                  </span>
                </p>
              </Link>
              <div className="navbar_desktop withsubmenunav">
                <span
                  className={`mainspan ${
                    [
                      "/digital-signage-software-for-hospitals",
                      "/digital-signage-software-for-education",
                      "/digital-signage-software-for-qsr",
                      "/digital-signage-software-for-fitness",
                      "/digital-signage-software-for-manufacturing-industry",
                      "/digital-signage-software-for-retail",
                      "/digital-signage-software-for-corporate",
                      "/digital-signage-software-for-transportation-supply-chain",
                      "/digital-signage-cms-software",
                    ].some((path) => pathname.includes(path))
                      ? "currentPage"
                      : ""
                  }`}
                >
                  Industries
                </span>
                <ResourcesDropdown />
              </div>
              <Link href={"/blog"} key={"blog"} aria-label={`blog`}>
                <p className="navbar_desktop">
                  <span className={pathname === "/blog" ? "currentPage" : ""}>
                    Blog
                  </span>
                </p>
              </Link>
            </div>
            <div className="nav_container_right_inner">
              <Link
                target="_blank"
                href={"https://app.signagemint.com/signin/"}
                key={"Login"}
                aria-label={`Login`}
              >
                <p className="navbar_desktop">
                  <span>Login</span>
                </p>
              </Link>
              <Link
                target="_blank"
                href={"https://app.signagemint.com/sign-up/"}
                key={"sign-up"}
                aria-label={`sign-up`}
              >
                <CustomButton
                  Text={"Try for FREE"}
                  PrimaryColorBtn
                  navBar
                  className="navbar_desktop"
                />
              </Link>
            </div>
          </div>
        </nav>
        <BurgerMenu pathname={pathname} />
      </div>
    </div>
  );
};

export default NavBar;
