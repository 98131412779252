"use client";
import React, { useState } from "react";
import CustomButton from "../Buttons/CustomButton";
import DemoPopup from "./DemoPopup";

const DemoButton = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <CustomButton
        Text={"Request a Demo"}
        onClick={() => setIsOpen(true)}
        {...props}
      />
      <DemoPopup open={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default DemoButton;
